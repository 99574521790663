import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appStoryblok]',
  standalone: true,
})
export class StoryblokDirective implements OnChanges {
  @Input('appStoryblok') appStoryblok!: string;

  constructor(private el: ElementRef) {}

  ngOnChanges() {
    if (typeof this.appStoryblok === 'undefined') {
      return;
    }

    let options = JSON.parse(
      this.appStoryblok.replace('<!--#storyblok#', '').replace('-->', '')
    );
    try {
      if (!this.el.nativeElement?.setAttribute) return;
      this.el.nativeElement.setAttribute(
        'data-blok-c',
        JSON.stringify(options)
      );
      this.el.nativeElement.setAttribute(
        'data-blok-uid',
        options.id + '-' + options.uid
      );
      this.el.nativeElement.classList.add('storyblok__outline');
    } catch (err) {
      console.log(err);
    }
  }
}
